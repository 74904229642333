import { apiUrl, axios } from '@/api/api.js';
import { generateErrors } from '@/main/utils/common';

export const uploadFile = async (file) => {
    try {
        const { data } = await axios.post(`${ apiUrl }/file`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
};
