<template>
    <div>
        <DataView :value="allRegisters" :rows="9" layout="grid">
            <template #header>
                <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-mb-2">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Прочие ресурсы</h5>
                    </div>
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center p-mt-0"></div>
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center">
                        <div class="p-inputgroup p-p-0 p-d-flex"></div>
                        <div
                            v-if='canEdit'
                            class="p-p-0 p-new-btn"
                        >
                            <Button @click="openAddDialog('Добавить')" class="p-button p-ml-3">Добавить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Ресурсы не найдены.</template>
            <template #grid="slotProps">
                <div class="p-col-12 p-md-4">
                    <div class="register-item">
                        <div class="p-d-flex p-card"
                             :style="{
                                backgroundImage: `url(${ slotProps.data.image ? slotProps.data.image : '' })`,
                                opacity: slotProps.data.attributes.active ? 1 : 0.6 }">
                            <a :href="slotProps.data.attributes.link" target="_blank" class="p-card-title p-p-3"
                               style="width: 100%;">
                                <div class="p-d-flex p-ai-center p-jc-between">
                                    <div class="register-name"></div>
                                </div>
                            </a>
                            <div v-show="isEditRegister">
                                <button v-show="isEditRegister" class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                                    <span class="pi pi-ellipsis-h"></span>
                                </button>
                                <Menu class="redLastListElement"
                                      :ref="`listMenu${ slotProps.data.id }`"
                                      :model="listMenuItemsComputed"
                                      :popup="true"
                                      :baseZIndex="10" />
                            </div>
                        </div>

                        <div class="p-card-content">
                            <div class="register-name">{{ slotProps.data.attributes.name }}</div>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>
        <Dialog :visible.sync="addElementDialog" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 600px" class="customDialog">
            <template #header>
                <h3 class="p-mb-0 customTitle p-px-2">{{ dialogMode === 'Добавить' ? 'Добавить ресурс' : 'Редактировать ресурс' }}</h3>
            </template>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-2">
                <label>Наименование*</label>
                <InputText id="system" type="text" v-model="v$.registerName.$model"
                           placeholder="Введите наименование"
                           :class="v$.registerName.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.registerName)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-2">
                <label>Ссылка на внешний сервис*</label>
                <InputText id="system" type="text" v-model="v$.registerLink.$model"
                           placeholder="Введите ссылку"
                           :class="v$.registerLink.$invalid && submitted ? 'p-error' : ''" />
                <small v-show="submitted" v-for="error in showErrors(v$.registerLink)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-mb-0 p-px-2 p-py-0">
                <div class="p-d-flex p-jc-start p-ai-center uploadItem p-py-2">
                    <Button :label="uploadFile.file ? 'Изменить файл ' : 'Загрузить файл'" icon="pi pi-upload" iconPos="left" class="uploadwidth p-mr-2" @click="choiceFile(uploadFile.id)" />
                    <span class="fileNameLength">{{ uploadFile.file && uploadFile.file.name }}</span>
                    <input :ref="`uploadItem${uploadFile.id}`" type="file" @change="onFileSelect($event)" class="uploadInput" />
                    <Button icon="pi pi-times-circle" class="p-button-raised p-button-danger p-button-text" @click="removeFile" v-if="uploadFile.file" />
                </div>
            </div>
            <div class="p-field p-col-12 p-mb-0 p-px-2 p-py-0 p-field-checkbox checkBox">
                <Checkbox id="checkOption1" name="option" :binary="true" v-model="v$.active.$model" />
                <label>Активный</label>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button" v-if="dialogMode === 'Добавить'" type="button" @click="createRegister" :disabled="registerName.length < 1">
                        <span class="p-button-label">Добавить</span><span class="p-ink"></span>
                    </Button>
                    <Button class="p-button" v-else type="button" @click="saveRegister(currentRegister)" :disabled="registerName.length < 1">
                        <span class="p-button-label">Изменить</span><span class="p-ink"></span>
                    </Button>
                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { getAllRegisters, deleteRegister, addRegister, patchRegister } from '@/api/registers';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength, url } from '@vuelidate/validators';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from '@/api/common';
import { USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';

const { registerWrite } = USER_PERMISSIONS_MAP

export default {
    name: 'registersList',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            selectedRegister: null,
            filters: {},
            filter: {},
            allRegisters: [],
            registersIncluded: [],
            currentPage: 1,
            totalRecords: null,
            addElementDialog: false,
            registerName: '',
            registerLink: '',
            submitted: false,
            uploadFile: {
                label: 'Загрузить файл',
                id: uuidv4(),
                file: null,
            },
            active: true,
            activeLabel: 'Активировать',
            currentRegister: null,
            isEditRegister: false,
        };
    },
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        });
        return {
            registerName: { ...standart(), maxLength: { ...maxLength(200), $message: 'Длина заголовка не должна превышать 200 символов' } },
            registerLink: {
                required: { ...required, $message: 'Поле обязательно к заполнению' },
                url: { ...url, $message: 'Введите корректный URL' }
            },
            active: {},
        };
    },
    methods: {
        paginationChange(event) {
            this.currentPage = event.page + 1;
            this.pageSize = event.rows;
            this.getRegisters();
        },
        async getRegisters() {
            try {
                this.$emit('loadingChange', true);
                const { registers, included, meta } = await getAllRegisters(this.currentPage, this.pageSize,
                    !this.isEditRegister ? { active: true } : {}
                );

                this.allRegisters = registers.map((register) => {
                    if (register.relationships.image && register.relationships.image.data) {
                        const image = included.find((f) => f.id === register.relationships.image.data.id);
                        register['image'] =  image ? image.attributes.uri : null;
                        register['file'] = image;
                    }

                    return register;
                })
                .sort((a, b) => {
                    return b.attributes.sort - a.attributes.sort;
                });

                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$emit('loadingChange', false);
                this.$requestError(error.message);
            }

            this.$emit('loadingChange', false);
        },
        async removeRegister() {
            try {
                this.$emit('loadingChange', true);
                const response = await deleteRegister(this.currentRegister);
                if (response.message) {
                    this.$requestError(response.message);
                    return;
                }
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        openAddDialog(mode) {
            this.submitted = false;
            this.dialogMode = mode;
            if (mode === 'Добавить') {
                this.registerName = '';
                this.registerLink = '';
            }
            this.addElementDialog = true;
        },
        async createRegister() {
            this.submitted = true;
            if (this.v$.$invalid) {
                return;
            }
            try {
                this.$emit('loadingChange', true);
                this.addElementDialog = false;
                const fileArray = await this.checkFileForUpload();
                const dataToServer = {
                    data: {
                        type: 'register',
                        id: null,
                        attributes: {
                            name: this.registerName,
                            link: this.registerLink,
                            description: '',
                            sort: 0,
                            active: this.active,
                        }
                    },
                };
                if (fileArray.length) {
                    dataToServer.data.relationships = {
                        image: {
                            data: {
                                type: 'file',
                                id: fileArray[0].id,
                            },
                        },
                    };
                } else {
                    dataToServer.relationships = { image: { data: {} } };
                }
                const result = await addRegister(dataToServer);

                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                if (result) {
                    this.closeDialog();
                    window.location.reload();
                }
            } catch (error) {
                this.$requestError(error.message);
                return;
            }
        },
        editRegister() {
            const data = this.allRegisters.find((register) => register.id === this.currentRegister);
            this.registerName = data.attributes.name;
            this.registerLink = data.attributes.link;
            this.active = data.attributes.active;
            if (data.file) {
                this.uploadFile = {
                    label: 'Загрузить файл',
                    id: data.file.id,
                    file: data.file.attributes,
                };
            } else {
                this.uploadFile = {
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                };
            }

            this.openAddDialog();
        },
        async activateRegister() {
            this.active = !this.active;
            const result = await patchRegister(this.currentRegister, {
                data: {
                    type: 'register',
                    id: this.currentRegister,
                    attributes: {
                        active: this.active,
                    }
                }
            });
            if (result.message) {
                this.$requestError(result.message);
                return;
            }

            this.v$.$anyDirty = false;
            if (result) {
                this.closeDialog();
                await this.getRegisters();
            }
        },
        async saveRegister(id) {
            this.submitted = true;
            if (this.v$.$invalid) {
                return;
            }
            const fileArray = await this.checkFileForUpload();
            const dataToServer = {
                data: {
                    type: 'register',
                    id: id,
                    attributes: {
                        name: this.registerName,
                        link: this.registerLink,
                        description: this.registerName,
                        sort: 0,
                        active: this.active,
                    },
                },
            };
            if (fileArray.length) {
                dataToServer.data.relationships = {
                    image: {
                        data: {
                            type: 'file',
                            id: fileArray[0].id,
                        },
                    },
                };
            } else {
                dataToServer.data.relationships = { image: null };
            }

            try {
                const result = await patchRegister(this.currentRegister, dataToServer);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.v$.$anyDirty = false;
                if (result) {
                    this.closeDialog();
                    await this.getRegisters();
                }
            } catch (error) {
                this.$requestError(error.message);
                return;
            }
        },
        async checkFileForUpload() {
            try {
                let fileArray = [];
                let resultArray = [];

                if (this.uploadFile.file) {
                    if (this.uploadFile.file.uri) resultArray.push(this.uploadFile);
                    else {
                        const fD = new FormData();
                        fD.append('file', this.uploadFile.file);
                        fileArray.push(uploadFile(fD).then(({ data }) => resultArray.push(data[0])));
                    }
                }

                await Promise.all(fileArray);
                return resultArray;
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        closeDialog() {
            this.addElementDialog = false;
            this.uploadFile = {
                label: 'Загрузить файл',
                id: uuidv4(),
                file: null,
            };
            this.registerName = '';
            this.registerLink = '';
            this.active = null;
            this.submitted = false;
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async toggleRowMenu(event, slotProps) {
            const { data: { id } } = slotProps;

            if (id !== this.currentRegister) {
                this.$refs[`listMenu${ this.currentRegister }`] && this.$refs[`listMenu${ this.currentRegister }`].hide(event);
            }
            this.currentRegister = id;
            this.active = slotProps.data.attributes.active;
            this.registerName = slotProps.data.attributes.name;
            this.registerLink = slotProps.data.attributes.link;
            this.activeLabel = this.active === false ? 'Активировать' : 'Снять активность';
            const menuEl = this.$refs[`listMenu${ id }`]

            if (menuEl) {
                menuEl.toggle(event);
            }
            await this.$nextTick();
            const { x, y } = event;
            const { $el } = menuEl;
            if ($el.style) {
                $el.style.top = `${ y + 15 }px`;
                $el.style.left = `${ x - 230 }px`;
            }
        },
        choiceFile(itemIndex) {
            this.$refs[`uploadItem${ itemIndex }`].click();
        },
        onFileSelect(event) {

            if (!this.uploadFile.file) {
                this.uploadFile = {
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                };
            }
            this.uploadFile.file = event.target.files[0];
        },
        removeFile() {
            this.uploadFile.file = null;
        },
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'currentUser',
            'userPermissionsObject',
            'accessibleItems'
        ]),
        listMenuItemsComputed() {
            return this.accessibleItems([
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editRegister();
                    },
                },
                {
                    label: this.activeLabel,
                    icon: 'pi icon custom-check-square',
                    command: () => {
                        this.activateRegister();
                    }
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: () => this.removeRegister()
                        })
                    },
                    class: 'remove',
                    permissionAccess: [registerWrite]
                },
            ]);
        },
        canEdit() {
            return [registerWrite].some(p => this.userPermissionsObject[p])
        }
    },
    watch: {
        selectedRegister() {
            this.$router.push(`/control/registers/${this.selectedRegister.id}`);
        },
    },
    created() {
        /** Кнопка "Добавить" и меню регистров (Редактироваь, Активировать, Удалить)
         *  доступны только пользователю с правом registerWrite
         **/
        if (this.currentUser) {
            if (this.currentUser.userPermissions.find((permission) => permission.id === 'registerWrite')) {
                this.isEditRegister = true;
            }
        }
    },
    async mounted() {
        await this.getRegisters();
    },
};
</script>

<style lang="scss" scoped>
.card {
    position: relative;
}
.p-new-btn {
    height: 100%;
    .p-button {
        height: 100%;
        align-items: flex-end;
    }
}
::v-deep .p-dataview {
    .p-nogutter {
        margin: -1rem !important;
    }
    .p-dataview-header {
        background-color: #FFFFFF !important;
        border: none !important;
        padding-right: 0;
    }
    .customDialog {
        width: 480px;
    }
}
::v-deep .p-dataview-emptymessage {
    margin: 24px;
}
::v-deep .redLastListElement {
    width: 240px;
    position: fixed !important;
    display: block !important;
    z-index: 1042;
    transform: translateX(-10%);
    .remove {
        span {
            color: red !important;
        }
    }
    .p-menuitem {
        height: 36px;
        display: flex;
        align-items: center;
        width: 100%;
        .p-menuitem-link {
            height: 100%;
        }

        .pi {
            font-size: 1rem !important;
        }
        &:hover {
            background: #eaeaea;
        }
        &-link {
            white-space: nowrap;
            width: 100%;
            padding: 0 1rem;
            .empty {
                width: 14px;
                height: 14px;
                margin-right: 7px;
            }
            &:hover {
                background: unset;
            }
            &:focus {
                box-shadow: unset;
            }
        }
    }
}
::v-deep .register-item {
    margin: 0.75rem;
    .register-name {
        color: #272727;
        font-size: 16px;
    }
    .p-card {
        min-height: 220px;
        width: 100%;
        position: relative;
        background-size: cover;
        border: 1px solid #dee2e6;
        .p-card-title {
            margin-right: 24px;
        }
        .p-card .p-card-content {
            padding: 0.25rem 0 !important;
        }
        .product-grid-item-top {
            padding: 12px 50px 10px 41px;
            height: 100%;
            background: #eaeaea;
            border: 1px solid #e0e0e0;

            .pi {
                font-size: 8px;
            }
        }
        .p-panel-header-icon {
            position: absolute;
            top: 18px;
            right: 18px;
            z-index: 1;
            width: 24px;
            height: 24px;
            border-radius: 50%;

            &:hover {
                background: rgba(135, 148, 163, 0.25);
            }

            .pi {
                font-size: 14px;
            }
        }
    }
}
::v-deep .p-field > label {
    margin-top: 0.5rem;
}
::v-deep .customDialog {
    width: 480px;
}
::v-deep .uploadItem {
    .p-button-raised,
    .p-button.p-button-danger:enabled:focus {
        box-shadow: none;
    }
    .fileNameLength {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.uploadInput {
    display: none;
}
.uploadwidth {
    width: 164px;
}
::v-deep .p-fileupload {
    margin-bottom: 16px;
}
</style>

